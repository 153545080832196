/* =============
   Helper clasess
============= */

.text-capital {
    text-transform: capitalize;
}

.text-underline {
    text-decoration: underline;
}

.text-center {
    text-align: center;
}

.text-upper {
    text-transform: uppercase;
}

.text-lower {
    text-transform: lowercase;
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.m-center-auto {
    margin: 0 auto;
}

.d-block {
    display: block;
}

.p-0 {
    padding: 0 !important;
}

.p-20 {
    padding: 20px !important;
}

.p-30 {
    padding: 30px !important;
}

.p-l-0 {
    padding-left: 0 !important;
}

.p-r-0 {
    padding-right: 0 !important;
}

.p-t-0 {
    padding-top: 0 !important;
}

.p-t-10 {
    padding-top: 10px !important;
}

.p-t-20 {
    padding-top: 20px !important;
}

.p-t-30 {
    padding-top: 30px !important;
}

.p-t-40 {
    padding-top: 40px !important;
}

.p-b-0 {
    padding-bottom: 0 !important;
}

.p-b-10 {
    padding-bottom: 10px !important;
}

.p-b-15 {
    padding-bottom: 15px !important;
}

.p-b-30 {
    padding-bottom: 30px !important;
}

.p-b-20 {
    padding-bottom: 20px !important;
}

.p-b-24 {
    padding-bottom: 24px !important;
}

.p-l-r-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.m-0 {
    margin: 0 !important;
}

.m-10 {
    margin: 10px !important;
}

.m-5 {
    margin: 5px !important;
}

.m-r-2 {
    margin-right: 2px !important;
}

.m-r-5 {
    margin-right: 5px !important;
}

.m-r-10 {
    margin-right: 10px !important;
}

.m-r-15 {
    margin-right: 15px !important;
}

.m-r-30 {
    margin-right: 30px !important;
}

.m-l-5 {
    margin-left: 5px !important;
}

.m-l-10 {
    margin-left: 10px !important;
}

.m-l-15 {
    margin-left: 15px !important;
}

.m-l-20 {
    margin-left: 20px !important;
}

.m-t-5 {
    margin-top: 5px !important;
}

.m-t-0 {
    margin-top: 0 !important;
}

.m-t-10 {
    margin-top: 10px !important;
}

.m-t-15 {
    margin-top: 15px !important;
}

.m-t-20 {
    margin-top: 20px !important;
}

.m-t-30 {
    margin-top: 30px !important;
}

.m-t-40 {
    margin-top: 40px !important;
}

.m-b-0 {
    margin-bottom: 0 !important;
}

.m-b-5 {
    margin-bottom: 5px !important;
}

.m-b-10 {
    margin-bottom: 10px !important;
}

.m-b-15 {
    margin-bottom: 15px !important;
}

.m-b-20 {
    margin-bottom: 20px !important;
}

.m-b-30 {
    margin-bottom: 30px !important;
}

.w-xs {
    min-width: 80px;
}

.w-sm {
    min-width: 95px;
}

.w-md {
    min-width: 110px;
}

.w-lg {
    min-width: 140px;
}

.m-h-40 {
    min-height: 40px;
}

.m-h-50 {
    min-height: 50px;
}

.l-h-34 {
    line-height: 34px;
}

.font-normal {
    font-weight: normal;
}

.font-light {
    font-weight: 300;
}

.font-12 {
    font-size: 12px !important;
}

.font-13 {
    font-size: 13px !important;
}

.font-14 {
    font-size: 14px !important;
}

.font-15 {
    font-size: 15px !important;
}

.font-16 {
    font-size: 16px !important;
}

.font-18 {
    font-size: 18px !important;
}

.font-22 {
    font-size: 22px !important;
}

.font-24 {
    font-size: 24px !important;
}

.font-25 {
    font-size: 25px !important;
}

.font-30 {
    font-size: 30px !important;
}

.wrapper-md {
    padding: 20px;
}

.pull-in {
    margin-left: -15px;
    margin-right: -15px;
}

.pull-in-card {
    margin-left: -20px !important;
    margin-right: -20px !important;
}

.b-0 {
    border: none !important;
}

.vertical-middle {
    vertical-align: middle;
}

.b-r-0 {
    border-radius: 0 !important;
}

.br-12{
  border-radius: 12px;
}

.br-4{
  border-radius: 4px;
}

.b-r-2 {
    border-radius: 2px;
}
.b-r-4 {
    border-radius: 4px;
}
.b-r-8 {
    border-radius: 8px;
}
.b-r-12 {
    border-radius: 12px;
}
.br-l-4{
  border-left: 4px solid transparent;
}
.bx-shadow {
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.mx-box {
    max-height: 380px;
    min-height: 380px;
}

.thumb-sm {
    height: 32px;
    width: 32px;
}

.thumb-md {
    height: 48px;
    width: 48px;
}

.thumb-lg {
    height: 88px;
    width: 88px;
}

.text-dark {
    color: black;
}

.upper-case {
    text-transform: uppercase;
}

.lower-case {
    text-transform: lowercase;
}

.cursor-pointer {
    cursor: pointer;
}


.dashedButton {
    color: $primary-theme !important;
    border-bottom: 2px dashed $primary-theme !important;
}

.dashedBorder {
    border-bottom: 1px dashed #85868F !important
}

.primary-text-color {
    color: $primary-theme !important;
    font-size: $primary-font-size;
    font-family: $font-primary;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.spc-btwn {
    justify-content: space-between;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: end;
}

.align-center {
    align-items: center;
}

.align-start {
    align-items: flex-start;
}

.flex-1 {
  flex: 1
}

.flex-2 {
  flex: 2
}

.flex-3{
  flex: 3;
}

.flex-4{
  flex: 4;
}

.flex-5{
  flex: 5;
}

.flex-6{
  flex: 6;
}

.flex-7{
  flex: 7;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

/* spacing */
.m-t-24 {
    margin-top: 24px;
}

.m-b-24 {
    margin-bottom: 24px;
}

.m-r-24 {
    margin-right: 24px;
}

.m-l-24 {
    margin-left: 24px;
}

.m-t-16 {
    margin-top: 16px;
}

.m-b-16 {
    margin-bottom: 16px;
}

.m-r-16 {
    margin-right: 16px;
}

.m-l-16 {
    margin-left: 16px;
}

.m-t-8 {
    margin-top: 8px;
}

.m-b-8 {
    margin-bottom: 8px;
}

.m-r-8 {
    margin-right: 8px;
}

.m-l-8 {
    margin-left: 8px;
}

.p-h-8 {
    padding-left: 8px;
    padding-right: 8px;
}
.p-v-4 {
    padding-top: 4px;
    padding-bottom: 4px;
}
.p-v-8 {
    padding-top: 8px;
    padding-bottom: 8px;
}

.p-h-24 {
    padding-left: 24px;
    padding-right: 24px;
}

.p-h-16 {
    padding-left: 16px;
    padding-right: 16px;
}

.p-v-24 {
    padding-top: 24px;
    padding-bottom: 24px;
}

.p-v-16 {
    padding-top: 16px;
    padding-bottom: 16px;
}

.p-l-24 {
    padding-left: 24px;
}

.p-r-24 {
    padding-right: 24px;
}

.p-t-24 {
    padding-top: 24px;
}

.p-b-24 {
    padding-bottom: 24px;
}

.p-l-16 {
    padding-left: 16px;
}

.p-r-16 {
    padding-right: 16px;
}

.p-t-16 {
    padding-top: 16px;
}

.p-b-16 {
    padding-bottom: 16px;
}

.p-l-8 {
    padding-left: 8px;
}

.p-r-8 {
    padding-right: 8px;
}

.p-8 {
    padding: 8px;
}
.p-12 {
    padding: 12px;
}
.p-16 {
    padding: 16px;
}

.p-24 {
    padding: 24px;
}

.g-24 {
    gap: 24px;
}

.cursorPointer {
    cursor: pointer;
}

.collapse-row {
    height: 48px;
    padding: 11px 16px 11px 16px;
}

.no-m {
    margin: 0px !important;
}

.no-p {
    padding: 0px !important;
}

.min-h-400 {
    min-height: 400px;
    overflow-y: auto;
}

.min-h-300 {
    min-height: 300px !important;
    overflow-y: auto;
}

.full-h {
    height: 100%;
}

.full-w {
    width: 100%;
}

.partion {
    margin-right: rgba(0, 0, 0, 6%);
    ;
}

.alignCenter {
    align-items: center;
}

.g-4 {
    gap: 4px;
}

.g-8 {
    gap: 8px;
}

.g-12 {
    gap: 12px
}

.g-16 {
    gap: 16px
}

.g-24 {
    gap: 24px;
}
.flex-1{
    flex:1
}
.flex-2{
    flex:2
}
.flex-3{
    flex:4
}
.flex-4{
    flex:4
}
.flex-5{
    flex:5
}

.rotate-180{
  transform: rotate(180deg);
}