    /*
Template Name: Adminto Dashboard
Author: CoderThemes
Email: coderthemes$gmail.com
File: Menu
*/
    
    .orange {
        color: $orange;
    }
    
    .green {
        color: #00A510!important;
    }
    
    .text-danger {
        color: #FC3131!important;
    }
    
    .topbar {
        left: 0px;
        position: fixed;
        right: 0px;
        top: 0px;
        z-index: 1061;
        .topbar-left {
            background: $custom;
            float: left;
            text-align: center;
            height: 71px;
            position: relative;
            width: 250px;
            z-index: 1;
            .logo {
                line-height: 70px;
            }
        }
    }
    
    .navbar-default {
        background-color: $custom;
        border-radius: 0px;
        border: none;
        margin-bottom: 0px;
        padding: 0px 10px;
        // box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
        .navbar-left {
            li {
                padding-right: 10px;
            }
        }
        .button-menu-mobile {
            display: none;
        }
        .nav-link {
            padding: 0;
            line-height: 70px;
            color: rgba($dark, 0.8);
        }
        .dropdown-toggle {
            &:after {
                content: initial;
            }
        }
        .menu-left {
            overflow: hidden;
        }
        .topbar-right-menu {
            li {
                float: left;
            }
        }
    }
    
    .logo {
        color: $white !important;
        font-size: 32px;
        i {
            display: none;
        }
        span {
            span {
                color: $white;
            }
        }
    }
    
    .user-box {
        text-align: center;
        .user-img {
            position: relative;
            // height: 88px;
            // width: 88px;
            display: inline;
            // img {
            //     width: 53px;
            //     margin-right: 24px;
            // }
            img[alt="KycHub"] {
                width: 100px;
            }
        }
        h5 {
            a {
                color: $muted;
            }
        }
        .user-status {
            height: 12px;
            width: 12px;
            position: absolute;
            bottom: 7px;
            right: 15px;
            i {
                font-size: 15px;
            }
        }
        .user-status.away {
            i {
                color: $warning;
            }
        }
        .user-status.offline {
            i {
                color: $danger;
            }
        }
        .user-status.online {
            i {
                color: $success;
            }
        }
        .user-status.busy {
            i {
                color: $muted;
            }
        }
        ul {
            li {
                a {
                    color: $muted;
                    &:hover {
                        color: $custom;
                    }
                }
            }
        }
    }
    
    .notification-box {
        ul {
            max-height: 60px;
            li {
                a {
                    font-size: 26px;
                    color: $white;
                    display: block;
                    line-height: 70px;
                }
            }
        }
        .noti-dot {
            position: relative;
            top: -45px;
        }
        .pulse {
            width: 2px;
            height: 2px;
            border: 3px solid $danger;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            border-radius: 30px;
            background-color: $danger;
            z-index: 10;
            position: absolute;
            right: 6px;
        }
        .dot {
            border: 4px solid $danger;
            background: transparent;
            -webkit-border-radius: 60px;
            -moz-border-radius: 60px;
            border-radius: 60px;
            height: 30px;
            width: 30px;
            -webkit-animation: pulse 3s ease-out;
            -moz-animation: pulse 3s ease-out;
            animation: pulse 3s ease-out;
            -webkit-animation-iteration-count: infinite;
            -moz-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
            position: absolute;
            top: -12px;
            left: 2px;
            z-index: 1;
            opacity: 0;
        }
        @-moz-keyframes pulse {
            0% {
                -moz-transform: scale(0);
                opacity: 0.0;
            }
            25% {
                -moz-transform: scale(0);
                opacity: 0.1;
            }
            50% {
                -moz-transform: scale(0.1);
                opacity: 0.3;
            }
            75% {
                -moz-transform: scale(0.5);
                opacity: 0.5;
            }
            100% {
                -moz-transform: scale(1);
                opacity: 0.0;
            }
        }
        @-webkit-keyframes pulse {
            0% {
                -webkit-transform: scale(0);
                opacity: 0.0;
            }
            25% {
                -webkit-transform: scale(0);
                opacity: 0.1;
            }
            50% {
                -webkit-transform: scale(0.1);
                opacity: 0.3;
            }
            75% {
                -webkit-transform: scale(0.5);
                opacity: 0.5;
            }
            100% {
                -webkit-transform: scale(1);
                opacity: 0.0;
            }
        }
    }
    
    .side-menu {
        top: 70px;
        // width: 250px;
        width: 250px;
        z-index: 10;
        background: $bg-leftbar;
        bottom: 0;
        margin-top: 0;
        padding-bottom: 30px;
        position: fixed;
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
        transition: ease-in-out;
        transition-duration: 300ms;
        @media (max-width: 540px) {
            width: 100%;
        }
        .waves-effect {
            .waves-ripple {
                background-color: rgba($custom, 0.3);
            }
        }
    }
    
    .margin-bottom-zero {
        margin-bottom: 0px !important;
    }
    
    .margin-bottom-zero-mb {
        margin-bottom: 0 !important;
    }
    
    .margin-top {
        margin-top: 24px;
    }
    
    .multi-tabs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        .tab-item {
            cursor: pointer;
            background: transparent;
            padding: 10px 0px !important;
            border-bottom: 2px solid transparent;
            font-size: 16px;
            margin-right: 20px;

            &.active{
                
                border-color: transparent !important;
                border-bottom: 2px solid transparent !important;
                padding: 10px 0px !important;
                
                    background: linear-gradient(97deg, #04DFB8 4.74%, #4198FF 101.31%);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    -webkit-background-clip: text;
            
                
            }

            // &:hover{
            //     background-color: transparent;
            //     border-color: transparent;
            //     border-bottom: 2px solid $primary-theme !important;
            // }
        }
    }
    
    .info-tabs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .tab-item-info {
            cursor: pointer;
            padding: 10px 0px;
            width: calc(100%/3.3);
            display: flex;
            justify-content: center;
            box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
        }
        .fa {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            padding: 2px 3px;
        }
        .fa-times {
            padding: 2px 4px;
        }
    }
    
    input::-webkit-input-placeholder {
        /* Edge */
        color: #85858F !important;
    }
    
    input :-ms-input-placeholder {
        /* Internet Explorer */
        color: #85858F !important;
    }
    
    input ::placeholder {
        color: #85858F !important;
    }
    
    .aml-details-card {
        display: flex;
        flex-direction: column;
        .fa {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            padding: 2px 3px;
            margin-right: 10px;
        }
        .fa-times {
            padding: 2px 4px;
            color: #FC3131 !important;
        }
        .fa-check {
            color: #14E167 !important;
        }
    }
    
    .button-global {
        .btn {
            background-color: $orange;
            border: none;
            border-radius: 10px;
            color: #fff;
            min-width: 180px;
            font-weight: 500;
            font-size: 16px;
            height: 48px;
        }
        .btnWhite {
            background-color: #fff;
            border: none;
            border-radius: 10px;
            color: $secondaryButton;
            min-width: 180px;
            font-weight: 500;
            font-size: 16px;
            height: 48px;
            border: 2px solid $secondaryButton;
        }
    }
    
    .btn-filled-orange {
        border: 1px solid $orange;
        box-sizing: border-box;
        border-radius: 24px;
        height: 48px;
        padding: 10px 22px;
        background-color: $orange;
        color: $white;
        min-width: 150px;
        font-weight: 500;
        font-size: 16px;
        align-items: center;
        display: flex;
        justify-content: center;
    }
    
    .btn-outline-orange {
        border: 2px solid $secondaryButton;
        box-sizing: border-box;
        border-radius: 24px;
        height: 48px;
        color: $secondaryButton;
        min-width: 150px;
        font-weight: 500;
        font-size: 16px;
        padding: 10px 22px;
        background-color: $white;
        align-items: center;
        display: flex;
        justify-content: center;
    }
    
    .btn-text-orange {
        background-color: transparent;
        box-sizing: border-box;
        border: none;
        border-radius: 24px;
        height: 48px;
        color: $orange;
        min-width: 150px;
        font-weight: 500;
        font-size: 16px;
        padding: 10px 22px;
    }
    
    .processLength {
        position: absolute;
        top: 0% !important;
        left: 40% !important;
        .process-no {
            padding: 0 10px !important;
        }
        .active {
            color: #0094FF !important;
        }
    }
    
    .do-form-group {
        margin: 0px 0px 24px 0px;
        border-radius: 6px;
        padding: 12px 20px;
        font-size: 16px;
        label {
            margin: 0px !important;
            color: $hintColor !important;
        }
        input {
            float: right;
            text-align: end;
            border: none !important;
            background-color: transparent !important;
            width: 45% !important;
            color: $inputTextColor !important;
        }
        select {
            float: right;
            text-align: end;
            border: none !important;
            background-color: transparent !important;
            width: 45% !important;
            color: $inputTextColor !important;
        }
        select {
            text-align: center !important;
        }
    }
    
    // select{
    //   margin: 0px 0px 24px 0px;
    //   border: none !important;
    //   border-color: transparent !important;
    //   margin: 0px 0px 24px 0px;
    //   padding: 12px;
    //   width: 100%;
    //   border-radius: 6px;
    // }
    .do-form-group-radio {
        margin: 0px 0px 24px 0px;
        color: #85868F;
        font-size: 16px;
        padding: 12px 20px;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        label {
            margin: 0 !important;
        }
        .radio-button {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            .items {
                margin-right: 20px;
                input {
                    margin-left: 8px;
                }
            }
        }
    }
    
    .do-modal-form-group {
        margin: 0px 0px 24px 0px;
        border-radius: 10px;
        padding: 12px 20px;
        color: #85868F;
        background-color: #354650;
        position: relative;
        font-size: 16px;
        label {
            margin: 0px !important;
        }
        input,
        select {
            float: right;
            text-align: end;
            border: none !important;
            background-color: #354650 !important;
            width: 45% !important;
            color: #85868F;
        }
        select {
            text-align: center !important;
        }
    }
    
    .idv-score {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        .idv-score-text {
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            align-self: center;
            min-height: 100%;
        }
    }
    
    .circle-bar-progress {
        display: flex;
        flex-direction: row;
    }
    
    .form-container {
        margin: 0px 0px 24px 0px;
        border-radius: 10px;
        padding: 12px 20px;
        color: #85868F;
        font-size: 16px;
        p {
            display: inline;
            max-width: 60%;
        }
        span {
            float: right;
        }
    }
    
    .detail-form-container {
        font-size: 16px;
        margin: 0px 0px 16px 0px;
        background-color: transparent !important;
        display: flex;
        align-items: center;
        p {
            display: inline;
            padding: 12px 24px;
            width: 50%;
            float: right;
            margin-bottom: 0px;
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
            border-radius: 12px;
            background-color: transparent;
        }
        h5 {
            font-weight: 400;
            width: 50%;
            display: inline-block;
            margin-bottom: 0px;
        }
        &.no-white-space{
            p{
                white-space: unset !important;
            }
        }
    }
    
    .risk-button {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;
        button {
            padding: 10px !important;
            border: 0 !important;
            cursor: pointer !important;
            margin-left: 20px;
            border-radius: 0 !important;
            width: 150px;
        }
        .btn-onboard {
            background-color: #14E167 !important;
            color: #fff !important;
        }
        .btn-reject {
            background-color: #FC3131 !important;
            color: #fff !important;
        }
        .btn-secondary {
            color: #85868F !important;
        }
    }
    
    .exceed-tabs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .tab-item {
            cursor: pointer;
            background: transparent;
            color: #1E2026;
            font-size: 16px;
            margin-right: 12px;
        }
        .active {
            color: $orange;
        }
    }
    
    .modal-form-container {
        margin: 24px 0px 0px 0px;
        font-size: 16px;
        select {
            background-color: transparent;
            border: 1px solid $orange;
            border-radius: 10px;
            width: 100%;
            padding: 12px 24px;
        }
        p {
            margin-bottom: 0;
        }
        input {
            margin-top: 24px;
            background-color: $backgroundInput;
            border: none;
            border-radius: 10px;
            width: 100%;
            padding: 12px 24px;
            color: $inputTextColor;
        }
    }
    
    table,
    .table-bordered th,
    .table-bordered td {
        border: none !important;
    }
    
    .table tbody tr {
        cursor: pointer;
    }
    
    .table-bordered th,
    .table-bordered td,
    .table-nested .td {
        font-size: 16px;
        vertical-align: middle;
        font-family: "Asap" !important;
        overflow-x: auto;
        font-weight: 400;
    }

    .table-nested {
      .td {
        text-align: left;
        white-space: normal;
      }
      
      .table-nested-header {
        font-weight: bold;
      }

      .table-nested-header,.table-nested-row{
        width: 100%;
        display: flex;
        justify-content: space-between;
        text-align: center;
      }
      
      // .table-nested-header.table-nested-row {
      //   // border-bottom: 2px solid gray;
      // }
      
      .table-nested-row {
        white-space: nowrap;
        overflow: hidden;
      }
      .table-nested-row:last-child {
        border: none
      }
      .td {
        display: inline-block;
        padding: 8px;
        flex: 1;
      }
      
      .clickable {
        cursor: pointer;
        
      }

      .loop-container{
        &.tb:nth-child(1){
          padding-left: 16px;
        }
      }
    }
    
    
    
    .nav-tabs,
    .tab-content {
        border: none !important;
    }
    
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus,
    select:-webkit-autofill:active {
        -webkit-transition: background-color 9999s ease-out;
    }
    
    .back-with-description {
        display: flex;
        flex-direction: row;
        height: 48px;
        cursor: pointer;
        align-items: center;
        width: fit-content;
        // width: 100%;
        // position: fixed;
        // z-index: 100;
        // margin-top: -22px;
        .close-arrow {
            margin-right: 20px;
            .fa {
                font-size: 35px;
                color: $secondary-theme;
                font-weight: 900;
            }
        }
    }

    .back-description {
        display: flex;
        flex-direction: row;
        height: 48px;
        cursor: pointer;
        align-items: center;
        width: fit-content;
        .close-arrow {
            margin-right: 20px;
            .fa {
                font-size: 35px;
                color: $secondary-theme;
                font-weight: 900;
            }
        }
    }
    
    .questionaire {
        margin-top: 50px;
    }
    
    .table-bordered tr, .table-nested .td {
        vertical-align: middle !important;
        .action {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            .items {
                padding: 7px 12px;
                cursor: pointer;
                margin-right: 8px;
                width: max-content;
                border-radius: 5px;
                span {
                    font-size: 16px;
                    color: #85868F !important;
                    margin-left: 12px;
                }
                .icons {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    background-color: transparent !important;
                }
            }
        }
    }
    
    table {
        border-collapse: separate;
        border-spacing: 0px;
    }
    
    .filteration {
        .filter-items {
            width: 100%;
            .btn-primary {
                height: 48px !important;
                background-color: #0094FF !important;
                border: none !important;
                border-radius: 10px !important;
                width: 180px !important;
            }
            select,
            input {
                height: 48px !important;
                width: 100%;
                border-radius: 0px !important;
                font-family: "Asap" !important;
            }
            input {
                background-color: transparent !important;
                border: 1px solid #D2D2D2 !important;
                border-radius: 4px !important;
                position: relative;
                padding-left: 30px;
            }
            .fa-search {
                position: absolute;
                top: 16px;
                left: 20px;
                color: #85868F;
            }
        }
    }
    
    .content-page {
        margin-left: 250px;
        transition: ease-in-out;
        transition-duration: 300ms;
        height: 100%;
        padding-top: 65px;
        .content{
            min-height: calc(100vh - 65px);
        }
    }
    
    .nav-section {
        margin-left: 250px;
        overflow: visible;
    }
    
    #wra .button-menu-mobile {
        background: transparent;
        border: none;
        color: rgba($white, 0.7);
        font-size: 21px;
        line-height: 68px;
        &:hover {
            color: $white;
        }
    }
    
    .sidebar-inner {
        height: $height;
    }
    
    #sidebar-menu,
    #sidebar-menu ul,
    #sidebar-menu li,
    #sidebar-menu a {
        border: 0;
        font-weight: normal;
        line-height: 1;
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        text-decoration: none;
        min-height: 48px;
    }
    
    #sidebar-menu ul {
        margin: 0px 12px;
    }
    
    #sidebar-menu {
        width: 100%;
        a {
            line-height: 1.3;
        }
        ul {
            li {
                .menu-arrow {
                    -webkit-transition: -webkit-transform .15s;
                    -o-transition: -o-transform .15s;
                    transition: transform .15s;
                    position: absolute;
                    right: 20px;
                    display: inline-block;
                    text-rendering: auto;
                    line-height: 18px;
                    font-size: 16px;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    -webkit-transform: translate(0, 0);
                    -ms-transform: translate(0, 0);
                    -o-transform: translate(0, 0);
                    transform: translate(0, 0);
                    &:before {
                        content: '\f2fb';
                    }
                }
                a.subdrop .menu-arrow {
                    -ms-transform: rotate(90deg);
                    -webkit-transform: rotate(90deg);
                    -o-transform: rotate(90deg);
                    transform: rotate(90deg);
                }
            }
            ul {
                border-radius: 8px;
                padding: 4px 0px;
                // display: none;
                li {
                    border-top: 0;
                    min-height: 48;
                    padding-right: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }
                li.active {
                    a {
                        border-radius: 12px;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        min-height: unset;
                        color: $secondary-theme;
                    }
                }
                a {
                    -webkit-transition: all 0.3s ease-out;
                    -moz-transition: all 0.3s ease-out;
                    -o-transition: all 0.3s ease-out;
                    -ms-transition: all 0.3s ease-out;
                    transition: all 0.3s ease-out;
                    min-height: unset;
                    display: flex;
                    justify-content: flex-end;
                    i {
                        margin-right: 5px;
                    }
                }
                ul {
                    a {
                        padding-left: 80px;
                    }
                }
            }
        }
        .label {
            margin-top: 2px;
        }
    }
    
    #sidebar-menu>ul>li>a {
        color: lighten($dark, 35%);
        display: block;
        //padding: 4px 20px;
        margin: 4px 0px;
        background-color: $bg-leftbar;
        //border-left: 3px solid transparent;
        border-radius: 0!important;
        &:hover {
            color: $custom;
            text-decoration: none;
        }
    }
    
    #sidebar-menu>ul>li>a>span {
        vertical-align: middle;
    }
    
    #sidebar-menu {
        ul {
            li {
                a {
                    i {
                        display: inline-block;
                        font-size: 16px;
                        line-height: 17px;
                        margin-left: 3px;
                        margin-right: 15px;
                        text-align: center;
                        vertical-align: middle;
                        width: 20px;
                    }
                    i.md {
                        font-size: 18px;
                    }
                    .drop-arrow {
                        float: right;
                        i {
                            margin-right: 0px;
                        }
                    }
                }
            }
        }
    }
    
    #sidebar-menu>ul>li>a.active {
        border-left: none;
        color: $custom !important;
    }
    
    .menu-title {
        padding: 12px 20px !important;
        letter-spacing: .035em;
        pointer-events: none;
        cursor: default;
        font-size: 13px;
    }
    
    #sidebar-menu ul ul {
        display: none;
    }
    
    #wrapper.enlarged {
        .menu-title,
        .menu-arrow {
            display: none !important;
        }
        .user-box {
            .user-img {
                height: 48px;
                width: 48px;
                img {
                    width: 68px;
                }
            }
            h5 {
                display: none!important;
            }
        }
        #sidebar-menu {
            ul {
                ul {
                    border: 2px solid #f4f8fb;
                    margin-top: -5px;
                    padding-top: 5px;
                    z-index: 9999;
                    background-color: $white;
                }
            }
        }
        .left.side-menu {
            width: 70px;
            // z-index: 5;
            z-index: 9999;
            #sidebar-menu>ul>li>a {
                padding: 4px 13px;
                i {
                    margin-right: -20px !important;
                    font-size: 20px;
                }
            }
            // #sidebar-menu>ul>li>a.active {
            //     border-radius: 12px !important;
            // }
            .label {
                position: absolute;
                top: 5px;
                left: 35px;
                text-indent: 0;
                display: block !important;
                padding: .2em .6em .3em !important;
            }
            #sidebar-menu {
                ul>li {
                    position: relative;
                    white-space: nowrap;
                    &:hover>a {
                        position: relative;
                        width: 48px;
                        background: $lightdark;
                        color: $custom;
                        // border-color: $custom;
                    }
                    &:hover>ul {
                        display: block !important;
                        left: 70px;
                        position: absolute;
                        // width: 190px;
                        width: 210px;
                        a {
                            background: $white;
                            box-shadow: none;
                            padding-left: 15px;
                            position: relative;
                            width: 186px;
                            z-index: 6;
                            &:hover {
                                color: $custom;
                            }
                        }
                    }
                    &:hover {
                        a {
                            span {
                                display: none;
                            }
                        }
                    }
                }
                li {
                    .show-menu+ul {
                        display: block;
                        left: 70px;
                        position: absolute;
                        width: 190px;
                        a {
                            background: $white;
                            box-shadow: none;
                            padding-left: 15px;
                            position: relative;
                            width: 186px;
                            z-index: 6;
                            &:hover {
                                color: $custom;
                            }
                        }
                    }
                }
                a.subdrop {
                    color: $custom !important;
                }
                ul>li>ul {
                    display: none !important;
                }
                ul {
                    ul {
                        li {
                            &:hover>ul {
                                display: block;
                                left: 190px;
                                margin-top: -36px;
                                position: absolute;
                                width: 190px;
                            }
                        }
                        li>a {
                            span.pull-right {
                                -ms-transform: rotate(270deg);
                                -webkit-transform: rotate(270deg);
                                position: absolute;
                                right: 20px;
                                top: 12px;
                                transform: rotate(270deg);
                            }
                        }
                        li.active {
                            a {
                                color: $custom;
                            }
                        }
                    }
                }
                ul>li>a {
                    span {
                        display: none;
                        padding-left: 10px;
                        transition: ease-in-out;
                        transition-duration: 1s;
                    }
                }
            }
            .user-details {
                display: none;
            }
        }
        .content-page,
        .nav-section {
            margin-left: 70px;
        }
        .footer {
            left: 70px;
        }
        .topbar {
            .topbar-left {
                width: 70px !important;
                .logo {
                    span {
                        display: none;
                        opacity: 0;
                    }
                    i {
                        display: block;
                        line-height: 70px;
                        color: $dark !important;
                    }
                }
            }
        }
        #sidebar-menu>ul>li {
            &:hover>a.open {
                 :after {
                    display: none;
                }
            }
            &:hover>a.active {
                 :after {
                    display: none;
                }
            }
        }
        .logoutText {
            display: none;
        }
    }
    
    #wrapper.right-bar-enabled {
        .right-bar {
            right: 0;
        }
        .left-layout {
            left: 0;
        }
    }
    /* Right sidebar */
    
    .side-bar.right-bar {
        float: right !important;
        right: -266px;
        top: 0px;
    }
    
    .side-bar {
        -moz-transition: all 200ms ease-out;
        -webkit-transition: all 200ms ease-out;
        background-color: $white;
        box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
        display: block;
        float: left;
        height: $height;
        position: fixed;
        transition: all 200ms ease-out;
        width: 240px;
    }
    
    .right-bar {
        background: $white !important;
        z-index: 999 !important;
        h4 {
            border-bottom: 1px solid rgba($muted, 0.5);
            padding: 4px 10px 10px 18px;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 600;
            color: $dark;
            letter-spacing: 0.03em;
        }
        .right-bar-toggle {
            float: right;
            line-height: 46px;
            font-size: 20px;
            color: #333;
            padding: 0 10px;
        }
    }
    
    .notification-list {
        height: 100%;
        .list-group-item {
            border-bottom: 1px solid rgba($muted, 0.2) !important;
            margin-bottom: 0;
            background-color: $white;
        }
        .list-group-item.active {
            background-color: rgba($muted, 0.1);
        }
    }
    
    .user-list {
        .user-list-item {
            .avatar {
                float: left;
                margin-right: 5px;
                width: 30px;
                height: 30px;
                img {
                    border-radius: 50%;
                    width: $width;
                }
            }
            .icon {
                float: left;
                margin-right: 5px;
                height: 30px;
                width: 30px;
                border-radius: 50%;
                text-align: center;
                i {
                    color: $white;
                    line-height: 30px;
                    font-size: 16px;
                }
            }
            .user-desc {
                margin-left: 40px;
                span.name {
                    color: $dark;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                    width: 100%;
                    overflow: hidden;
                }
                span.desc {
                    color: $muted;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                    width: 100%;
                    overflow: hidden;
                    font-size: 12px;
                }
                span.time {
                    font-size: 11px;
                    color: $custom;
                    font-style: italic;
                }
            }
        }
    }
    
    .do-form-group2 {
        margin: 0px 0px 24px 0px;
        border-radius: 6px;
        padding: 12px 20px;
        font-size: 16px;
        background-color: #F8F8F8;
        input {
            border: none !important;
            background-color: transparent !important;
            width: 100% !important;
            color: $inputTextColor !important;
        }
        .customSelect {
            border: none !important;
            background-color: transparent !important;
            width: 100% !important;
            color: $inputTextColor !important;
        }
        select {
            border: none !important;
            background-color: transparent !important;
            width: 100% !important;
            color: $inputTextColor !important;
        }
        select {
            text-align: center !important;
        }
    }
    
    .customSelect {
        margin: 0px 0px 24px 0px;
        border-radius: 6px;
        padding: 12px 20px;
        font-size: 16px;
        color: #85868F;
        float: right;
        text-align: end;
        border: none !important;
        background-color: transparent !important;
        width: 100% !important;
    }
    
    .modal-basic-css {
        padding: 36px;
        .details {
            margin-bottom: 50px;
            p {
                font-size: 16px;
                font-weight: 500;
                text-align: center;
            }
        }
        .button-global {
            display: flex;
            justify-content: center;
            .btn {
                background-color: $orange;
                border: none;
                border-radius: 10px;
                color: #fff;
                min-width: 180px;
                font-weight: 500;
                font-size: 16px;
                height: 48px;
            }
            .btn-cancel {
                background-color: #fff;
                border: none;
                border-radius: 10px;
                color: $orange;
                min-width: 180px;
                font-weight: 500;
                font-size: 16px;
                height: 48px;
                border: 1px solid $orange;
            }
        }
    }
    
    #wrapper.enlargedTransaction {
        .content-page {
            margin-right: 416px; //340px;
            transition: ease-in-out;
            transition-duration: 1s;
            @media (max-width: 992px) {
                margin-right: 0;
            }
        }
        .transSlider {
            // display: block;
            .tr-pages {
                // width:416px;
                // transform: translateX(416px);
                right: 0!important;
                margin-right: 0!important;
                transition: ease-in-out;
                transition-duration: 1s;
            }
        }
    }
    /* Seach */
    
    .app-search {
        position: relative;
        button {
            position: absolute;
            background-color: transparent;
            border: none;
            top: 4px;
            right: 8px;
            color: rgba($white, 0.7);
            &:hover {
                color: $white;
            }
        }
        .form-control,
        .form-control:focus {
            border: 1px solid rgba($white, 0.15);
            font-size: 13px;
            height: 34px;
            color: $white;
            font-weight: 600;
            padding-left: 20px;
            padding-right: 40px;
            margin-top: 18px;
            margin-left: 20px;
            background: rgba($white, 0.1);
            box-shadow: none;
            border-radius: 30px;
            width: 190px;
        }
    }
    
    //input.app-search-input {
    //  &::-webkit-input-placeholder {
    //    color: rgba($white, 0.7);
    //    font-weight: normal;
    //  }
    //  &:-moz-placeholder {
    //    color: fade($white, 70%);
    //  }
    //  &::-moz-placeholder {
    //    color: fade($white, 70%);
    //  }
    //  &:-ms-input-placeholder {
    //    color: fade($white, 70%);
    //  }
    //}
    $tab: 990px;
    @media (max-width: $tab) {
        .exceed-tabs {
            justify-content: unset !important;
        }
    }