@import './variables';

.accordian-view{
    .fa-angle-right{
        font-size: 20px;
        font-weight: 900;
        color: $primary-theme;
        cursor: pointer;
        &.to-up{
            transform: rotate(-90deg);
        }
    }
}

.fix-height{
  height: 400px;
  overflow-y: auto;
}

.accordian-card {
    background-color: transparent !important;
    &.collapsed {
        .fa-angle-up {
            transform: rotate(180deg);
            padding-bottom: 0px;
            margin-top: 3px !important;
        }
        .fa-angle-down {
            transform: rotate(180deg);
            padding-bottom: 0px;
        }
    }
    .lable-group {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        height: 20px;
        cursor: pointer;
        .icon-i {
            height: 28px;
            color: $primary-theme;
            margin: 1px 0px 0px 10px;
            .fa-angle-up {
                font-size: 20px;
                font-weight: 800;
                margin-top: 0;
            }
            .fa-angle-down {
                font-size: 20px;
                font-weight: 800;
                margin-top: 0;
            }
        }
    }
}

.grey-icon{
  font-size: 20px !important;
  font-weight: 900 !important;
  color: $lightgray !important;
  cursor: pointer;
}

.white-icon{
  font-size: 20px !important;
  font-weight: 900 !important;
  color: $white !important;
  cursor: pointer;
}