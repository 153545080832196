@import url('https://fonts.googleapis.com/css2?family=Asap:wght@400;500;700&display=swap');
$custom: #71b6f9; //lighter blue
// Color variables
$white: #ffffff;
$primary: #188ae2; //light blue
$success: #00a510; // success green
$info: #35b8e0; //blueish
$warning: #f9c851; // yellowish warning
$danger: #FC3131;
$purple: #5b69bc;
$pink: #ff8acc;
$inverse: #3b3e47;
$muted: #3A3A3A;
$light: #eeeeee;
$dark: #435966;
$dark2: #1E1F23;
$lightdark3: #333333;
$lightdark4: #444444;
$lightdark5: #555555;
$lightdark7: #797979;
$light5: #ebeff2;
$light3: #f3f3f3;
$light9: #f9f9f9;
$light-alt: #fafafa;
$lightdark-alt: #cccccc;
$lightdark: #f4f8fb;
$lightgray: #7a8c9a;
$lightdark8: #6a6a6a;
$lightdark9: #b4b4b4;
// Previous Theme
$orange: #00D4FF;
$headingColor: #6A6A6A;
$inputTextColor: #6A6A6A;
$backButton: #6A6A6A;
$hintColor: #B4B4B4;
$textButton: #00D4FF;
$secondaryButton: #B4B4B4;
$darkteel: #2F3E47;
$backgroundInput: #F2F2F2;

// Gradient Colors:
$warning-gradient: linear-gradient(100deg, #F18E46 1.08%, #F94337 100%);
$success-gradient: linear-gradient(95deg, #26E78A 1.24%, #17B18C 97.56%);
$danger-gradient: linear-gradient(283deg, #F32626 0%, #EE3A85 97.96%);
$cta_color: linear-gradient(96.71deg, #04DFB8 4.74%, #4198FF 101.31%);

// Light Mode New Theme SetUp

$bg-1 : var(--bg-1);
$bg-2 : var(--bg-2);
$kyc-stroke : var(--kyc-stroke);
$primary-card : var(--primary-card);
$text-grey : var(--text-grey);
$text-dark : var(--text-dark);
$text-extra-dark : var(--text-extra-dark);
$menu-active-color : var(--menu-active-color);
$menu-bg-color : var(--menu-bg-color);
$menu-text-color : var(--menu-text-color);
$menu-button-color : var(--menu-button-color);
$menu-logo-color : var(--menu-logo-color);

// Dark Mode New Theme SetUp

$dark-bg-1 : var(--dark-bg-1);
$dark-bg-2 : var(--dark-bg-2);
$dark-kyc-stroke : var(--dark-kyc-stroke);
$dark-primary-card : var(--dark-primary-card);
$dark-text-grey : var(--dark-text-grey);
$dark-text-dark : var(--dark-text-dark);
$dark-text-extra-dark : var(--dark-text-extra-dark);
$dark-menu-active-color : var(--dark-menu-active-color);
$dark-menu-bg-color : var(--dark-menu-bg-color);
$dark-menu-text-color : var(--dark-menu-text-color);
$dark-menu-button-color : var(--dark-menu-button-color);
$dark-menu-logo-color : var(--dark-menu-logo-color);

// Common Color Code

$primary-theme : var(--primary-theme);
$secondary-theme : var(--secondary-theme);
$button-color : var(--button-color);
$transparent-bg : var(--transparent-bg);
$inactive-color : var(--inactive-color);
$inactive-bg-color : var(--inactive-bg-color);
$text-success-color: var(--text-success-color);
$text-danger-color: var(--text-danger-color);
$text-warning-color: var(--text-warning-color);
$red-alert-color: var(--red-alert-color);


// Width variables

$width: 100%;

// Height variables

$height: 100%;

// Font size 

$small-font-size: 12px;
$base-font-size: 14px;
$primary-font-size: 16px;
$secondary-font-size: 18px;
$tertiary-font-size: 20px;

// Border Radius

$primary-border-radius: 12px;
$secondary-border-radius: 4px;
$chips-border-radius: 24px;

// Leftbar background 

$bg-leftbar: #252f35;

//Font variables

$font-primary: 'Asap' !important;
$font-secondary: 'Asap Medium' !important;
$font-tertiary: 'Asap Bold' !important;

// Variables for Height

$button-height-46: 46px;
$button-height-48: 48px;
$button-height-36: 36px;
$button-height-32: 32px;