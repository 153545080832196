// Common Tags
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
label,
pre {
    margin: 0;
}

// Cards
.card-box,
.bg-1-card,
.bg-2-card,
.highlight-card,
.secondary-card,
.modal-primary-card {
    border-radius: $primary-border-radius;
    padding: 24px;
}

// Scroll bar

.card-is-scrollable{
    max-height: 100vh;
    overflow-y: auto;

    &.activity-log{
        height: 90vh;
        overflow-x: hidden;
    }

    &::-webkit-scrollbar{
        width: 5px;
    }
}

.info-primary-card {
    border-radius: 12px;
    min-height: 48px;
    padding: 12px 24px;
    min-width: 256px;
    max-width: 448px;
  
    &.no-max-width {
      max-width: unset !important;
    }
  }

.mapping-card {
    min-height: 48px;
    max-height: max-content;
    border-radius: 8px;
    font-size: $primary-font-size;
    max-width: 100%;
}

// Tabs

.details-tabs{
    display: flex;
    flex-wrap: wrap;
    .item{
        font-size: $primary-font-size;
        font-family: $font-primary;
        height: 36px;
        white-space: nowrap;
        display: flex;
        align-items: center;
        padding: 0px 24px;
        cursor: pointer;
        border-radius: 12px;
        margin-right: 16px;

        &.active{
            border: 2px solid $secondary-theme !important;
            box-shadow: 0px 0px 6px rgba(0, 189, 227, 0.5);
        }
    }
}

// Text Headings   ** Please use only heading tag to apply these classes
.card-headings {
    // h5
    font-family: $font-secondary;
    font-size: $secondary-font-size;
}

.page-headings {
    // h4
    font-family: $font-secondary;
    font-size: $tertiary-font-size;
}

// Buttons  ** Button classes should be like this class="btn primary-button"
.btn {
    border-radius: $secondary-border-radius;
    height: 48px;
    min-width: 168px;
    padding: 0px 24px;
    cursor: pointer;
    border: none;
    font-family: $font-secondary;
    font-size: $primary-font-size;
    box-shadow: none !important;
}

.primary-button {
    color: $button-color !important;
    background-color: $primary-theme !important;
    &:disabled {
        background-color: $inactive-bg-color !important;
        color: $inactive-color !important;
    }
}

.hero-button {
    border-radius: $secondary-border-radius !important;
    color: $button-color !important;
    background: linear-gradient(97deg, #04DFB8 4.74%, #4198FF 101.31%) !important;
    &:disabled {
        pointer-events: none;
        opacity: 0.6;
    }

    &.h-36 {
        height: 36px !important;
        min-width: unset !important;
    }
    
    &.br-12{
        border-radius: $primary-border-radius !important; 
    }
}

.hero-danger-button {
    border-radius: $secondary-border-radius !important;
    color: $button-color !important;
    background: linear-gradient(104deg, #FD4894 3.09%, #EB2A36 97.19%) !important;

    &:disabled {
        pointer-events: none;
        opacity: 0.6;
    }
}

.hero-warning-button {
    border-radius: $secondary-border-radius !important;
    color: $button-color !important;
    background: linear-gradient(100deg, #F1A346 1.08%, #FC5D3A 100%) !important;

    &:disabled {
        pointer-events: none;
        opacity: 0.6;
    }
}

.secondary-button {
    color: $secondary-theme !important;
    background-color: $transparent-bg;
    border: 2px solid $secondary-theme !important;

    &:disabled{
        cursor: not-allowed;
        color: $secondary-theme!important;
        background-color: $transparent-bg;
        border: 2px solid $secondary-theme !important;
    }
}

.outline-button {
    color: $primary-theme !important;
    background-color: $transparent-bg;
    border: 2px solid $primary-theme !important;

    &:disabled{
        cursor: not-allowed;
        color: $primary-theme!important;
        background-color: $transparent-bg;
        border: 2px solid $primary-theme !important;
    }
}

.bg-1-outline-button {

    &:disabled{
        pointer-events: none;
        opacity: 0.6;
    }
}

.chips-button {
    background-color: $transparent-bg;
    border-radius: $chips-border-radius;
    font-size: $primary-font-size;
}

.chips-button-sm {
    background-color: $transparent-bg;
    border-radius: $chips-border-radius;
    padding: 8px 8px;
    min-width: 0px;
    height: 36px;
    font-size: $primary-font-size;
}

.red-alert-button {
    font-size: $primary-font-size;
    color: $button-color !important;
    background-color: $red-alert-color;
}

.filter-button {
    color: $secondary-theme !important;
    background-color: $transparent-bg;
    border: 2px solid $secondary-theme !important;
}

.filter-cancel-button {
    color: $secondary-theme !important;
    background-color: $secondary-theme;
}

.primary-text-button {
    color: $primary-theme !important;
    font-size: $primary-font-size;
    border-radius: 0px;
    background-color: $transparent-bg;
    border: none !important;
    span {
        border-bottom: 2px dashed $primary-theme;
    }
    &:disabled {
        color: $inactive-color;
        background-color: $transparent-bg;
        span {
            border-bottom: 2px dashed $inactive-color;
        }
    }
}

.secondary-text-button {
    font-size: $primary-font-size;
    color: $primary-theme !important;
    border-radius: 0px;
    background-color: $transparent-bg;
    border: none !important;
    span {
        border-bottom: 2px dashed $primary-theme;
    }
    &:disabled {
        color: inactive-color;
        background-color: $transparent-bg;
        span {
            border-bottom: 2px dashed inactive-color;
        }
    }
}

.text-transparent-button{
    min-width: 120px;
    height: 36px;
    white-space: nowrap;
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding: 0px 24px;
    font-size: $primary-font-size;

    &.secondary{
        color: $secondary-theme;
        cursor: pointer;
    }
}

.multi-button{
  border-radius: 12px;
  width: fit-content;
  transition: all 0.5s;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  button {
    height: 40px;
    border: none;
    padding: 0 0.75rem;
    background-color: inherit;
    &.active{
      border:1px solid $primary-theme !important;
    }
  }
  
  button:first-child {
    border-radius: 8px 0 0 8px;
  }
  
  button:last-child {
    border-radius: 0 8px 8px 0;
  }
}

.btn-large{
  display: flex;
  height: 36px;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  border-radius: 2px;
  border: none;
  &.warning-gradient{
    background: $warning-gradient;
  }
  &.success-gradient{
    background: $success-gradient;
  }
  &.danger-gradient{
    background: $danger-gradient;
  }
}

// Border gradients
.warning-gradient-border{
  border-image: $warning-gradient 30;
}

.success-gradient-border{
  border-image: $success-gradient 30;
}

.danger-gradient-border{
  border-image: $danger-gradient 30;
}

// Texts   ** Please use only para tag to apply these classes
.hint-text {
    font-size: $primary-font-size;
    font-family: $font-primary;
}

.hint-text-small {
    font-size: $small-font-size !important;
    font-family: $font-primary;
}

.highlight-text {
    font-size: $primary-font-size;
    font-family: $font-secondary;
    color: $secondary-theme !important;
}

.highlight-text-small {
    font-size: $small-font-size;
    font-family: $font-primary;
    color: $secondary-theme !important;
}

.red-alert-text {
    font-size: $primary-font-size;
    font-family: $font-secondary;
    color: $red-alert-color;
}

.user-input-text {
    font-size: $primary-font-size;
    font-family: $font-primary;
}

.pre-text{
    font-size: $primary-font-size;
    font-family: $font-secondary;
    color: $dark-text-dark;
}

.success-text {
    font-size: $primary-font-size;
    font-family: $font-secondary;
    color: $text-success-color !important;
}

.success-bg-color{
    background-color: $text-success-color !important;
}

.warning-text {
    font-size: $primary-font-size;
    font-family: $font-secondary;
    color: $text-warning-color !important;
}

.warning-bg-color{
    background-color: $text-warning-color !important;
}

.danger-text {
    font-size: $primary-font-size;
    font-family: $font-secondary;
    color: $text-danger-color !important;
}

.danger-bg-color{
    background-color: $text-danger-color !important;
}

// gradient text

.green-blue-text{
    background: -webkit-linear-gradient(97deg, #04DFB8 4.74%, #4198FF 101.31%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: $primary-font-size;
    font-family: $font-tertiary;
    cursor: pointer;
}

.yellow-orange-text{
    background: -webkit-linear-gradient(100deg, #F1A346 1.08%, #FC5D3A 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: $primary-font-size;
    font-family: $font-tertiary;
}

.light-dark-green-text{
    background: -webkit-linear-gradient(95deg, #0BCB6F 1.24%, #18C0B6 97.56%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: $primary-font-size;
    font-family: $font-tertiary;
}

.light-dark-voilet-text{
    background: -webkit-linear-gradient(283deg, #9F11E1 0%, #EE3AC6 97.96%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: $primary-font-size;
    font-family: $font-tertiary;
}

// Badegs

.risk-status-badge {
    cursor: default;
    height: 36px;
    white-space: nowrap;
    padding: 0px 12px;
    display: flex;
    align-items: center;
    color: $button-color;
    border-radius: 12px;
    max-width: fit-content;
    font-family: $font-secondary;
    font-size: $primary-font-size;

    &.secondary{
        cursor: pointer;
    }
  }

// Highlighted Boxes
.primary-highlight-box {
    height: 48px;
    border-radius: $primary-border-radius;
    margin-top: 24px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    min-height: 48px;
    padding: 0px 24px;
    label {
        font-size: $primary-font-size;
        font-family: $font-primary;
        margin: 0;
        min-width: fit-content;
        margin-left: 12px;
    }
    input,
    textarea {
        background-color: transparent;
        width: 100%;
        border: none;
        font-size: $primary-font-size;
        font-family: $font-secondary;
    }
}

.secondary-highlight-box {
    height: 48px;
    border-radius: $primary-border-radius;
    margin-top: 24px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    min-height: 48px;
    padding: 0px 24px;
    label {
        font-size: $primary-font-size;
        font-family: $font-primary;
        margin: 0;
        min-width: fit-content;
        margin-left: 12px;
    }
    input,
    textarea {
        background-color: transparent;
        width: 100%;
        border: none;
        font-size: $primary-font-size;
        font-family: $font-secondary;
    }
}

.customInput {
    position: relative;
    min-width: 280px;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 32px;
    &__label {
        position: absolute;
        left: 0;
        top: 5px;
        padding: 8px 20px;
        white-space: nowrap;
        transform: translate(0, 0);
        transform-origin: 0 0;
        transition: transform 120ms linear;
        font-family: "Asap";
        letter-spacing: 0.5px;
        font-weight: 400;
        line-height: 21px;
        font-size: 16px;
        margin-left: 5px;
        color: #b4b4b4;
    }
    &__field {
        box-sizing: border-box;
        display: block;
        width: 100%;
        padding: 12px 20px;
        background: transparent;
        border-radius: 12px;
        height: 48px;
        font-size: 16px;
        &:focus,
        &:not(:placeholder-shown) {
            &+.customInput__label {
                transform: translate(0.5rem, -45%) scale(0.9);
                padding: 0px 20px;
                padding-left: 9px;
                top: 0
            }
        }
    }
}

// Search Filter
.search {
    position: relative;
    min-width: 225px;
    max-width: 480px;
    input[type='text'] {
        height: 48px;
        width: 100%;
        border: none;
        padding: 0px 24px;
        border-radius: 8px;
    }
    p {
        position: absolute;
        top: 13px;
        right: 33px;
        font-size: 15px;
        color: $primary-theme;
        cursor: pointer;
    }
}

// Normal Input

.square-box-input{
    min-width: 150px;
    max-width: 480px;

    input[type='text'] {
        height: 36px;
        width: max-content;
        border: none;
        padding: 0px 12px;
        border-radius: 8px;
    }

    &.w-100{
        min-width: unset !important;
        max-width: unset !important;
        input[type='text'] {
            width: 100px !important;
        }
    }
}

// Radio Buttons
.form-radio-buttons {
    label {
        font-size: $primary-font-size;
        font-family: $font-primary;
        margin: 0;
        margin-left: 12px;
        cursor: pointer;
    }
    input[type="radio"] {
        position: absolute;
        opacity: 0;
        +.radio-label {
            &:before {
                content: '';
                border-radius: 100%;
                border: 1px solid $primary-theme;
                display: inline-block;
                width: 1.4em;
                height: 1.4em;
                position: relative;
                top: 0;
                margin-right: 1em;
                vertical-align: top;
                cursor: pointer;
                text-align: center;
                transition: all 250ms ease;
            }
        }
        &:checked {
            +.radio-label {
                &:before {
                    background-color: $secondary-theme;
                    border: 1px solid $secondary-theme;
                }
            }
        }
        &:focus {
            +.radio-label {
                &:before {
                    outline: none;
                    border-color: $secondary-theme;
                }
            }
        }
        +.radio-label {
            &:empty {
                &:before {
                    margin-right: 0;
                }
            }
        }
    }
}

// Toggle Buttons

/*
...Code structure should be like this for toggle button

<div class="toggle-wrapper loggedIn mx-2">
  <input type="checkbox" name="toggle" class="toggle">
  <label for="toggle" class="toggle-body">
    <span class="thumb"></span>
  </label>
</div>
*/

.toggle-wrapper {
    position: relative;
    width: 60px;
    border-radius: 25px;
    @media (max-width: 767px) {
        width: fit-content;
    }
    .toggle {
        opacity: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        z-index: 3;
        position: absolute;
        top: 0;
        cursor: pointer;
    }
    .toggle-body {
        display: block;
        width: 40px;
        height: 20px;
        box-sizing: border-box;
        padding: 10px;
        border-radius: 25px;
        z-index: 1;
    }
    .toggle:checked+.toggle-body {
        background: $secondary-theme;
    }
    .thumb {
        position: absolute;
        border-radius: 15px;
        padding: 0;
        top: 8px;
        left: 4px;
        width: 14px;
        height: 14px;
        background-color: #fff;
        background: -webkit-radial-gradient(10px 10px, circle cover, #fff);
        z-index: 2;
        margin-top: -5px !important;
    }
    .toggle:checked+.toggle-body .thumb {
        left: 20px;
    }
    .toggle:checked+.toggle-body .thumb:after {
        content: url('/assets/images/sidebar/light.png');
        width: 100%;
        height: 100%;
        top: -1px;
        left: -15px;
        border-radius: 15px;
        position: absolute;
    }
    .toggle+.toggle-body .thumb:after {
        content: url('/assets/images/sidebar/light.png');
        width: 100%;
        height: 100%;
        top: -2px;
        left: 20px;
        border-radius: 15px;
        position: absolute;
    }
}

// checkbox
// Use class 'customCheckbox'

.customCheckbox {
    display: block;
}

.customCheckbox input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
    &:disabled{
        cursor: default;
    }
}

.customCheckbox label {
    position: relative;
    cursor: pointer;
    margin-bottom: 0;
}

.customCheckbox label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 12px;
    border-radius: 4px;
}

.customCheckbox input:checked+label:before {
    border: 2px solid $secondary-theme;
}

.customCheckbox input:disabled+label:before, .customCheckbox input:disabled+label::after {
    cursor: default;
    opacity: 0.5;
}

.customCheckbox input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 9px;
    width: 6px;
    height: 13px;
    border: solid $secondary-theme;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.line-map {
    .dot {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        display: inline-block;
        right: -49%;
    }
    .vl {
        content: "";
        display: inline-block;
        height: 0.8rem;
        vertical-align: bottom;
        width: 100%;
        margin-right: -10px !important;
        margin-left: -10px !important;
        margin-top: 26px;
    }
}

.chip {
    display: inline-flex;
    float: left;
    padding: 0px 15px;
    border-radius: 10px;
    margin-right: 10px;
}

// Text area
.customTextArea textarea{
    background-color: transparent;
    width: 100%;
    min-height: 48px;
    max-height: 120px;
    border-radius: 10px;
    margin-bottom: 32px;
    padding: 12px 24px;
    font-family: "Asap";
    letter-spacing: 0.5px;
    font-weight: 400;
    line-height: 21px;
    font-size: 16px;
    resize: none;
}

// Tabs
.stepper-tabs{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .items{
        font-family: $font-secondary;
        font-size: $primary-font-size;

        &.active {
                background: linear-gradient(97deg, #04DFB8 4.74%, #4198FF 101.31%);
                background-clip: text;
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
        
            }
    }
}

// Small Pagination

.new-small-pagination{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    .item{
        font-family: $font-secondary;
        font-size: $primary-font-size;
        margin-right: 8px;
        &.box{
            width: 36px;
            height: 36px;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;

            .fa{
                font-size: 20px;
                font-weight: 900;
            }

            &.active{
                cursor: pointer;
                .fa{
                    color: $primary-theme !important;
                }
            }
        }
    }
}

// Flex Gaps

.gap-4{
    gap: 4px !important;
}

.gap-8{
    gap: 8px !important;
}

.gap-12{
    gap: 12px !important;
}

.gap-16{
    gap: 16px !important;
}

.gap-20{
    gap: 20px !important;
}

.gap-24{
    gap: 24px !important;
}

// Dropdown Menu

.dropdown-menu{
    max-height: 400px;
    overflow: auto;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.30) !important;

    &::-webkit-scrollbar{
        width: 5px;
    }

    label{
        margin-bottom: 0;
    }
}


// Selected Card

.selected-card{
    border: 2px solid $secondary-theme !important;
    box-shadow: 0px 0px 6px rgba(0, 189, 227, 0.5);
}


// Square Box

.square-box-icons{
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $primary-border-radius;

    &.disable{
        pointer-events: none;
        opacity: 0.4;
    }

    &.area-24{
        width: 24px;
        height: 24px; 
        border-radius: 4px;
        cursor: pointer;
        
        .close-icon{
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            @extend .hint-text;
        }
    }

    &.modal-cross{
        cursor: pointer;
        border: none !important;
    }
}

.mathatical-expressions-box{
    width: max-content;
    height: 36px;
    display: flex;
    border-radius: $secondary-border-radius;

    .sign-items{
        width: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        @extend .hint-text;

        &.disable-link{
            pointer-events: none;
            opacity: 0.4;
        }
    }
}


// Tab chips

.tree-tabs-chips{
    font-size: $primary-font-size;
    font-family: $font-primary;
    width: max-content;
    height: 36px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    padding: 0px 16px;
    gap: 12px;
    cursor: pointer;
    border-radius: 12px;

    &.active{
        border: 2px solid $secondary-theme !important;
        box-shadow: 0px 0px 6px rgba(0, 189, 227, 0.5);
    }

    &.red-flag-edit{
        border: 2px solid #04DFB8 !important;
        box-shadow: 0px 0px 6px 0px rgba(23, 124, 218, 0.25) !important;
    }

    &.disable-link{
        pointer-events: none;
        opacity: 0.4;
    }

    &.dashed-border{
        cursor: default;
    }

    &.external-attr{
        cursor: default;
    }

    &.font-14{
        font-size: 12px !important;
    }
}

.variable-chips{
    font-size: $primary-font-size;
    font-family: $font-primary;
    width: max-content;
    white-space: nowrap;
    padding: 4px 12px;
    border-radius: 8px;
}


// Span Arrow

.close-arrow-span{
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .fa-angle-right{
        font-size: 25px;
        font-weight: 900;

        &.rotate-to-90{
            transform: rotate(-90deg);
        }
    }

    &.cursor-default{
        cursor: default;
    }
}

.upload-file-container{
    .upload-file{
        border-radius: $primary-border-radius;
        border: 2px solid $secondary-theme;
    }
}

.attribute-map-card{
    border-radius: $secondary-border-radius;
    padding: 0px 16px;
    min-height: 46px;
    display: flex;
    align-items: center;
    @extend .hint-text;
}



/// gradient btns
.gradient-button-outlined {
    font-size: 1rem;
    color: $cta_color;
    border: none;
    display: inline-block;
    position: relative;
    background-color: transparent !important;
    background: $cta_color;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 0;
    height: 36px;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 8px;
    &.xl{
        min-width: 168px;
        height: 44px; 
        border-radius: 0px;
    }

  }

  
  .gradient-button-outlined:hover {
    background-color: transparent !important;
    background: $cta_color;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .gradient-button-outlined:before {
    margin: 0.06rem;
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
    padding: 2px;
    background: $cta_color;
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    border-radius: 8px;
   
    //border-radius: 12px;
  }
  .xl{
    &.gradient-button-outlined:before {
    min-width: 168px;
   
        height: 44px;
        border-radius: 0px;
    }

}

// New style 
.extra-button{
  font-size: $primary-font-size;
  font-family: $font-primary;
  height: 48px !important;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 0px 24px;
  cursor: pointer;
  border-radius: 12px;
  margin-right: 16px;

  &.active{
      border: 2px solid $secondary-theme !important;
      box-shadow: 0px 0px 6px rgba(0, 189, 227, 0.5);
  }
}