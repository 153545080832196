$successColor: #00a510;
$warningColor: #FC9031;
$dangerColor: #FC3131;
$themes: (
    dark: (
        bg-1: $dark-bg-1,
        bg-2: $dark-bg-2,
        kyc-stroke: $dark-kyc-stroke,
        primary-card: $dark-primary-card,
        text-grey: $dark-text-grey,
        text-dark: $dark-text-dark,
        text-extra-dark: $dark-text-extra-dark,
        menu-active-color: $dark-menu-active-color,
        menu-bg-color: $dark-menu-bg-color,
        menu-text-color: $dark-menu-text-color,
        menu-button-color: $dark-menu-button-color,
        menu-logo-color: $dark-menu-logo-color
    ),
    light: (
        bg-1: $bg-1,
        bg-2: $bg-2,
        kyc-stroke: $kyc-stroke,
        primary-card: $primary-card,
        text-grey: $text-grey,
        text-dark: $text-dark,
        text-extra-dark: $text-extra-dark,
        menu-active-color: $menu-active-color,
        menu-bg-color: $menu-bg-color,
        menu-text-color: $menu-text-color,
        menu-button-color: $menu-button-color,
        menu-logo-color: $menu-logo-color
    )
);

@mixin themify($themes: $themes) {

    @each $theme,
    $map in $themes {
        .theme-#{$theme} & {
            $theme-map: () !global;

            @each $key,
            $submap in $map {
                $value: map-get(map-get($themes, $theme), "#{$key}");
                $theme-map: map-merge($theme-map, ($key: $value)) !global;
            }

            @content;
            $theme-map: null !global;
        }
    }
}

@function themed($key) {
    @return map-get($theme-map, $key);
}


// Main Page BG

.content-page{
    @include themify($themes) {
        background-color: themed('bg-1');
    }
}


// Cards

.card-box{
    @include themify($themes) {
        background-color: themed('primary-card');
    }
}

.bg-2-card, .highlight-card{
    @include themify($themes) {
        background-color: themed('bg-2');
        &.bordered{
          border: 1px solid themed('kyc-stroke') !important;
        }
    }
}

.bg-1-card{
    @include themify($themes) {
        background-color: themed('bg-1');
    }
}

.secondary-card {
    @include themify($themes) {
        background-color: themed('bg-2');
    }
}

.mapping-card {
    @include themify($themes) {
        background-color: themed('bg-2');
    }
}

// Menu Colors

.side-menu{
    @include themify($themes) {
        background-color: themed('menu-bg-color');
    }
}

#sidebar-menu>ul>li>a.active {
    @include themify($themes) {
        color: themed('menu-active-color') !important;
        background-color: themed('menu-button-color') !important;
    }
}

#sidebar-menu ul ul li.active a {
    @include themify($themes) {
        color: themed('menu-active-color') !important;
    }
}

#sidebar-menu > ul > li > a{
    @include themify($themes) {
        color: themed('menu-text-color') !important;
        background-color: $transparent-bg !important;
    }
}

#sidebar-menu ul ul{
    @include themify($themes) {
        border: 2px solid themed('kyc-stroke');
    }
    li:hover{
        @include themify($themes) {
            border-left: 5px solid $primary-theme;
            background-color: themed('menu-button-color');
        } 
    }
    a{
        @include themify($themes) {
            color: themed('menu-text-color');
        }
    }
}

.navbar.navbar-default {
    @include themify($themes) {
        background-color: themed('primary-card') !important;
    }
}


// Texts

.card-headings, .page-headings, .user-input-text {
    @include themify($themes) {
        color: themed('text-dark');
    }
}

.hint-text, .hint-text-small{
    @include themify($themes) {
        color: themed('text-grey');
    }
}


// Input

.customInput__field {
    @include themify($themes) {
        border: 2px solid themed('kyc-stroke');
        color: themed('text-dark');
    }
}
.input-number{
    @include themify($themes) {
        border: 2px solid themed('kyc-stroke');
        color: themed('text-dark');
        background-color: transparent;
    }
}
.customInput__label{
    @include themify($themes) {
        background-color: themed('primary-card');
    }
    &.bg-2{
        @include themify($themes) {
            background-color: themed('bg-2');
        }
    }
    &.bg-1{
        @include themify($themes) {
            background-color: themed('bg-1');
        }
    }
  
}

// Toggle

.toggle-wrapper .toggle-body{
    @include themify($themes) {
        background-color: themed('text-grey');
    }
}

// Checkbox

.customCheckbox label:before {
    @include themify($themes) {
        border: 2px solid themed('text-grey');
    }
}

// Radio Button

// Radio Buttons
.form-radio-buttons {
    input[type="radio"] {
        &:checked {
            +.radio-label {
                &:before {
                    @include themify($themes) {
                        box-shadow: inset 0 0 0 4px themed('primary-card');
                    }
                }
            }
        }
        &:disabled {
            +.radio-label {
                &:before {
                    @include themify($themes) {
                        box-shadow: inset 0 0 0 4px themed('primary-card');
                        border-color: $inactive-bg-color;
                    }
                }
            }
        }
    }
}


// Search Input

.search, .square-box-input {
    input[type='text'] {
        @include themify($themes) {
            background-color: themed('bg-2');
            border: 2px solid themed('kyc-stroke');
            color: themed('text-dark');
        }
    }
}

.customTextArea textarea{
    @include themify($themes) {
        border: 2px solid themed('kyc-stroke') !important;
        color: themed('text-dark') !important;
    }
}

// Common Placeholder for all

::placeholder {
    @include themify($themes) {
        color: themed('text-grey');
    }
}


// Dropdown

.ng-select {
    .ng-select-container {
        @include themify($themes) {
            border: 2px solid themed('kyc-stroke') !important;
            color: themed('text-dark') !important;
        }
    }
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder{
  @include themify($themes) {
    color: themed('text-dark') !important;
  }
}

.ng-dropdown-panel {
    .ng-dropdown-panel-items {
        @include themify($themes) {
            background-color: themed('bg-2') !important;
            border: 2px solid themed('kyc-stroke') !important;
            border-radius: 12px;
        }

        .ng-option {
            @include themify($themes) {
                background-color: transparent !important;
            }

            .optionValue {
                @include themify($themes) {
                    background-color: themed('bg-2') !important;
                    color: themed('text-dark') !important;
                    border-bottom: 1px solid themed('kyc-stroke');
                }

                &.external-attr{
                    color: $primary-theme !important;
                }
            }

            .optionValue:hover {
                @include themify($themes) {
                    background-color: themed('bg-1') !important;
                    color: themed('text-dark') !important;
                    border-bottom: 1px solid themed('kyc-stroke');
                }

                &.external-attr{
                    background: transparent !important;
                    color: $primary-theme !important;
                }
            }
        }
    }
}

// Dropdown Border

.dropdown-menu {
    @include themify(($themes)) {
        background-color: themed('primary-card');
        border: 2px solid themed('kyc-stroke');
        color: themed('text-dark');
    }
}

.dropdown-item:hover{
    @include themify(($themes)) {
        background-color: themed('bg-1');
    } 
}


// Tables

.table-bordered thead tr, .table-nested .table-nested-header{
    @include themify($themes) {
        background-color: themed('bg-1') !important;
    } 
}

.table-nested{
  .clickable:hover{
    @include themify($themes) {
      background-color: themed('bg-1') !important;
    } 
  }

  .loop-container{
    @include themify($themes) {
      background-color: themed('primary-card') !important;
    } 
  }
}

.table-bordered td, .table-nested .td {
    @include themify($themes) {
        color: themed('text-grey');
    }
}

.table-bordered th {
    @include themify($themes) {
        color: themed('text-grey') !important;
    }
}

.table-bordered, .table-nested {
    tbody tr td, .table-nested-row {
        @include themify($themes) {
            border-bottom: 2px solid themed('kyc-stroke') !important;
            ;
        }
    }
}


// Tabs

.details-tabs {
    .item {
        @include themify($themes) {
            background-color: themed('bg-2') !important;
            border: 2px solid themed('kyc-stroke');
            color: themed('text-dark');
        }

        &.no-border-with-primary {
            border: none;
            color: $primary-theme;
        }
    }
}

.tree-tabs-chips{
    @include themify($themes) {
        background-color: themed('bg-2') !important;
        border: 2px solid themed('kyc-stroke');
        color: themed('text-dark');
    }

    &.bg-1{
        @include themify($themes) {
            background-color: themed('bg-1') !important;
        } 
    }

    &.dashed-border{
        @include themify($themes) {
            border: 2px dashed themed('kyc-stroke') !important;
            background-color: transparent !important;
        } 
    }

    &.external-attr{
        @include themify($themes) {
            background-color: themed('bg-1') !important;
        }
        border: 2px solid $text-danger-color !important;
    }
}

.variable-chips{
    @include themify($themes) {
        background-color: themed('primary-card') !important;
        border: 2px solid themed('kyc-stroke');
        color: themed('text-dark');
    }
}

.close-arrow-span .fa-angle-right{
    @include themify($themes) {
        color: themed('text-grey') !important;
    }
}

.upload-file-container{
    .upload-file{
        @include themify($themes) {
            background-color: themed('bg-1') !important;
        }
    }
}

.attribute-map-card{
    @include themify($themes) {
        background-color: themed('bg-1') !important;
    }
}

.circle-traget-line{

    .circle{
        @include themify($themes) {
            background: themed('kyc-stroke') !important;
        }
    }

    .line{
        @include themify($themes) {
            border-top: 3px solid themed('kyc-stroke') !important;
        }
    }
}

// Pagination

.ngx-pagination {
    .current {
        @include themify(($themes)) {
            background-color: themed('bg-2') !important;
            border: 2px solid themed('kyc-stroke');
        }
    }
}

.ngx-pagination a {
    @include themify($themes) {
        color: themed('text-dark') !important
    }
}

// Small Pagination

.new-small-pagination {
    .item {
        @include themify($themes) {
            color: themed('text-dark');

            &.box {
                border: 2px solid themed('kyc-stroke');
            }

            .fa{
                color: themed('text-grey') !important;
            }
        }
    }
}


// Lines

.setting-left-panel {
    @include themify($themes) {
        border-right: 2px solid themed('kyc-stroke');
    }
}

.horizontal-line {
    @include themify($themes) {
        border-top: 2px solid themed('kyc-stroke');
    }
}


.kyc-border-right {
    @include themify($themes) {
        border-right: 2px solid themed('kyc-stroke');
    }
}

.vertical-line {
    @include themify($themes) {
        border-left: 2px solid themed('kyc-stroke');
    }
}

.kyc-border {
    @include themify($themes) {
        border: 2px solid themed('kyc-stroke');
    }
}

.risk-status-badge {
    &.secondary {
        @include themify($themes) {
            border: 2px solid themed('kyc-stroke');
        }
    }
}

.partion-section {
    @include themify($themes) {
        border-left: 2px solid themed('kyc-stroke');
    }
}


// Buttons

.chips-button, .chips-button-sm{
    @include themify($themes) {
        color: themed('text-dark');
        border: 2px solid themed('kyc-stroke');
    }
}

.text-transparent-button{
    @include themify($themes) {
        background-color: themed('bg-2') !important;
    }
}

.btn-large{
  @include themify($themes) {
    color: themed('text-dark');
  }
}

.multi-button{
  @include themify($themes) {
    background: themed('primary-card');
    button {
      color: themed('text-dark');
      border: 1px solid themed('kyc-stroke');
      &:hover {
        background-color: themed('bg-1');
      }
      &.active{
        background-color: themed('bg-1');
      }
    }
    
  }
  
}

.text-transparent-dashed-button{
    @include themify($themes) {
        border:2px dashed themed('kyc-stroke');
        color:themed('text-dark');
    }
}


.bg-1-outline-button{
    @include themify($themes) {
        border: 2px dashed themed('kyc-stroke');
        color: themed('text-dark');
        background-color: themed('bg-1');
    }
}

// Common CSS

.detail-form-container{
    p{
        @include themify($themes) {
            background-color: themed('primary-card');
            color: themed('text-dark');
            border: 2px solid themed('kyc-stroke');
        }  
    }
    h5{
        @include themify($themes) {
            color: themed('text-grey');
        } 
    }
}

.info-primary-card {
    @include themify($themes) {
        background-color: themed('primary-card');
        border: 2px solid themed('kyc-stroke');
    }
}

.stepper-tabs .items {
    @include themify($themes) {
        color: themed('text-dark');
    }
}

.form-radio-buttons label{
    @include themify($themes) {
        color: themed('text-grey');
    }
}

.gauge-chart {
    .gauge-chart__label {
        @include themify($themes) {
            color: themed('text-dark') !important;
        }
    }
}

.action-dropdown {
    .btn-group #button-basic {
        @include themify($themes) {
            border: 2px solid themed('kyc-stroke') !important;
            background-color: themed('bg-2') !important;
        }
    }
}

// Half-circle Indicator theme

.half-arc::after {
    @include themify($themes) {
        background-color: themed('primary-card') !important;
    }
}

.setting-items {
    @include themify($themes) {
        color: themed('text-grey');
    }
}

.square-box-icons{
    @include themify($themes) {
        border: 2px solid themed('kyc-stroke');
    }

    &.bg-2{
        @include themify($themes) {
            background-color: themed('bg-2')
        }
    }

    &.bg-1{
        @include themify($themes) {
            background-color: themed('bg-1')
        }
    }
}

.mathatical-expressions-box{
    @include themify($themes) {
        border: 2px solid themed('kyc-stroke');
        background-color: themed('bg-2')
    }

    .sign-items.br{
        @include themify($themes) {
            border-right: 2px solid themed('kyc-stroke');
        }
    }
}

.setting-selected-chip {
    @include themify($themes) {
        background-color: themed('bg-2')
    }
}

.primary-highlight-box {
    @include themify($themes) {
        background-color: themed('bg-2');
    }
    label{
        @include themify($themes) {
            color: themed('text-grey');
        }
    }
    input{
        @include themify($themes) {
            color: themed('text-dark');
        }
    }
}

.accordian-card{
  @include themify($themes) {
    &.border-bottom{
      border-bottom: 1px solid themed('kyc-stroke') !important;
    }
  }
}

.collapse{
  @include themify($themes) {
    &.border-bottom{
      border-bottom: 1px solid themed('kyc-stroke') !important;
    }
    padding: 24px;
  }
}

.filter-container{
  @include themify($themes) {
    background-color: themed('primary-card');
    border-right: 2px solid themed('kyc-stroke') !important;
  
    .filter-header{
      padding: 8px 16px;
      border-bottom: 2px solid themed('kyc-stroke') !important;
    }
    .filter-details{
        background-color: themed('bg-1');
      .each-filter{
        padding: 8px 16px;
      }
    }
  }
}

.secondary-highlight-box {
    @include themify($themes) {
        background-color: themed('bg-1');
    }
    label{
        @include themify($themes) {
            color: themed('text-grey');
        }
    }
    input{
        @include themify($themes) {
            color: themed('text-dark');
        }
    }
}

.line-map {
    .vl {
        @include themify($themes) {
            border-top: 2px solid themed('kyc-stroke');
        }
    }
}

::-webkit-scrollbar-track {
    @include themify($themes) {
        background-color: themed('bg-1') !important;
    }
}

::-webkit-scrollbar-thumb {
    @include themify($themes) {
        background-color: $secondary-theme !important;
    }
}

.table>thead>tr>th {
    &.border-none {
        @include themify($themes) {
            border: none !important;
            vertical-align: middle;
        }
    }
}


